<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Figura Transporte">
          <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @list="list" :actions="['new','save','list']" />
          <div class="grid formgrid p-fluid">
            <div class="col-12">
              <TabPanel>
                <Fieldset :toggleable="true" legend="Informacion General">
                  <div class="p-fluid formgrid grid">
                    <FormDropdownComplex :labelInOption="'c_FiguraTransporte - Descripcion'" :labelInValue="'c_FiguraTransporte - Descripcion'" :wrapperClass="'field col-4'" label="Figura Transporte" v-model="entity.figure_type_code" :options="c_FiguraTransporte" optionLabel="c_FiguraTransporte" optionValue="c_FiguraTransporte" :tooltip="'Se obtiene del catalogo SAT c_FiguraTransporte'"/>
                    <FormInputText wrapperClass="field col-4" label="RFC" :valid="validate.validations.rfc" v-model="entity.rfc" />
                    <FormInputText wrapperClass="field col-4" label="Nombre" :valid="validate.validations.name" v-model="entity.name" />
                    <FormInputText wrapperClass="field col-4" label="Licencia" :valid="validate.validations.license_number" v-model="entity.license_number" />
                    <FormInputText wrapperClass="field col-4" label="Id Tributario" :valid="validate.validations.tributary_id" v-model="entity.tributary_id" v-on:change="taxResidence" :tooltip="'Llenar campo unicamente si la residencia fiscal es diferente a MEX'"/>
                    <FormDropdownComplex :id="'residence'" :disabled="isDisabled" :labelInOption="'c_Pais - Descripcion'" :labelInValue="'c_Pais - Descripcion'" :wrapperClass="'field col-4'" label="Residencia Fiscal" v-model="entity.tax_residence" :options="c_Pais" optionLabel="c_Pais" optionValue="c_Pais" :tooltip="'Se obtiene del catalogo SAT c_Pais'"/>
                  </div>
                </Fieldset>
              </TabPanel>
            <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" 
        :rowdelete="true" :rowedit="true" @deleted="confirmDelete" @edited="edit" />
        <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import { ConsignmentNoteFigure } from "../../../models/cfdi40/ConsingmentNoteFigure";
import {HeaderGrid, Rule, validate, fillObject, Toast, ErrorToast, satCatalogo,} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import { ConsignmentNoteTypesFigure } from '../../../models/cfdi40/ConsingmentNoteTypesFigure';
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
export default {
  mixins: [Session],
  props: {
    modal: null,
    id: null
  },
  data() {
    return {
      entity: null,
      entities: [],
      c_FiguraTransporte: [],
      c_Pais: [],
      deleteDialog: false,
      isDisabled: true,
      rules: [
      new Rule({ name: "figure_type_code" }),
        new Rule({ name: "rfc" }),
        new Rule({ name: "name" }),
        new Rule({ name: "license_number" }),
        new Rule({ name: "tributary_id" }),
        new Rule({ name: "tax_residence" }),
      ],
      validate: {
        valid: false,
        validations: {
          figure_type_code: null,
          rfc: null,
          name: null,
          license_number: null,
          tributary_id: null,
          tax_residence: null,
        },
      },
      headers: [
        new HeaderGrid("Codigo Figura", "figure_type_code"),
        new HeaderGrid("RFC", "rfc"),
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Licencia", "license_number"),
        new HeaderGrid("Id Tribiutario", "tributary_id"),
        new HeaderGrid("Residencia Fiscal", "tax_residence"),
      ],
      loading: false,
    };
   
  },
  components: { FormDropdownComplex, Loader, FormInputText, BasicFormToolbar, BasicDatatable, DeleteDialog },
  created() {
    this.entity = new ConsignmentNoteFigure(this.session);
  },
  async mounted() {
    await this.refresh();
    this.c_Pais = await satCatalogo(11);
    this.c_FiguraTransporte = await satCatalogo(21);
  },   
  methods: {
    deleted() {
        this.deleteEntity();
    },
    openNew() {
      this.entity = new ConsignmentNoteFigure(this.session);
      this.isDisabled = true;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
       this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }              
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          this.$emit('update', entity);
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra
          this.$emit('save', entity);
          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new ConsignmentNoteFigure(this.session);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new ConsignmentNoteFigure(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        
        this.c_RegimenFiscal = await new satCatalogo(2);
        this.c_UsoCFDI = await satCatalogo(15);
        this.typesfigures = await new ConsignmentNoteTypesFigure(this.session).all();      
        if (this.id) {
          this.entity.id = this.id;
          let entity = await this.entity.retrieve();
          this.entity = fillObject(this.entity, entity);
        }
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    async list() {
        this.loading = true;
        try {
          this.entity.branch = this.session.branch;
          this.entity.company = this.session.company;
          this.entities = await new ConsignmentNoteFigure().data({
            branch: this.session.branch,
            company: this.session.company,
            figure_type_code: this.entity.figure_type_code,
            rfc: this.entity.rfc,
            name: this.entity.name,
            license_number: this.entity.license_number,
            tributary_id: this.entity.tributary_id,
            tax_residence: this.tax_residence
          });
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      },

      async taxResidence(){
        this.isDisabled = false;
      }
  },
  computed: {
  }
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

</style>
